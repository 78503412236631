var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2 bg-white"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "8",
      "lg": "12"
    }
  }, [_c('br'), _c('a', {
    attrs: {
      "href": "\\"
    }
  }, [_c('h1', {
    staticClass: "brand-text text-primary"
  }, [_vm._v(_vm._s(_vm.appShortName))])]), _c('br'), _c('br'), _c('h2', {
    staticClass: "mb-1"
  }, [_vm._v("Lupa Kata Sandi? 🔒")]), _c('b-card-text', {
    staticClass: "mb-2",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" Masukan Email Kamu yang Telah Didaftarkan untuk Mengatur Ulang Kata Sandi ")]), _c('validation-observer', {
    ref: "forgotPassword",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-forgot-password-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "forgot-password-email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "forgot-password-email",
                  "state": errors.length > 0 ? false : null,
                  "name": "forgot-password-email",
                  "placeholder": "Masukkan Email"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": invalid,
            "variant": "primary",
            "block": ""
          }
        }, [_vm._v(" Kirim ")])], 1)];
      }
    }])
  }), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'auth-login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Kembali login ")], 1)], 1)], 1), _c('p', {
    staticClass: "clearfix mb-0 mt-4",
    staticStyle: {
      "position": "absolute",
      "top": "93%",
      "left": "5%"
    }
  }, [_c('span', {
    staticClass: "float-md-left d-block d-md-inline-block mt-25"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " " + _vm._s(_vm.appName) + " "), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(" . All rights Reserved ")])]), _c('br')])], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Forgot password V2"
    }
  })], 1)]), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "id": "modal-scoped",
      "centered": "",
      "title": "Using Component Methods"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref3) {
        var close = _ref3.close;
        return [_c('h5', [_vm._v("Verifikasi email")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref4) {
        var hide = _ref4.hide;
        return [_c('h5', [_vm._v(_vm._s(_vm.message))])];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref5) {
        var ok = _ref5.ok,
            cancel = _ref5.cancel,
            hide = _ref5.hide;
        return [_c('b-button', {
          attrs: {
            "size": "lg",
            "block": "",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.visitEmail();
            }
          }
        }, [_vm._v(" Lihat Email ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }